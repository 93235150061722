import './App.css';
import 'bulma/css/bulma.min.css';
import ImageIndex from "./ImageIndex";
import {useEffect, useState} from "react";


export interface PhotoData {
    image: string,
    index: number
};

function App() {

    const [photoData, setDisplayImage] = useState<PhotoData>({
        image: "", index: 0
    });

    useEffect(() => {
        const element = document.getElementById(photoData.index + "");
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [photoData]);

    function setImage(image: string, index: number) {
        setDisplayImage({image, index});
    }

    function handleClickOnImage(e: any) {
        setImage(e.currentTarget.getAttribute("data-image"), e.currentTarget.getAttribute("data-index"));
    }

    function handleCloseImage(e: any) {
        setImage("", e.currentTarget.getAttribute("data-index"));
    }

    return (
        <div className="App">
            {photoData.image === "" ?
                <div className="columns is-multiline is-vcentered">
                    {
                        ImageIndex.map(
                            (image, index) =>
                                <div className="column is-one-quarter-desktop is-half-tablet" key={index}>
                                    <div className="card">
                                        <div className="card-image" onClick={handleClickOnImage} data-image={image}
                                             data-index={index}>
                                            <figure className="image">
                                                <img src={`/photos/thumbnails/${image}`} id={"" + photoData.index}/>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                        )
                    }
                </div> :

                <div className={""}>

                    <figure className="image" onClick={handleCloseImage} data-index={photoData.index}>
                        <img src={`/photos/small/${photoData.image}`}/>
                    </figure>
                </div>
            }
        </div>
    );
}

export default App;
